import React from "react";
import Helmet from "react-helmet";
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import Logo from "../images/logo.inline.svg";
import Mode from "../images/mode.inline.svg";

const TemplateWrapper = ({ children }) => (
  <div className="container-fluid site__wrapper">
    <Helmet>
      <html lang="fr" />
      <meta name="keywords" content="osroots, devops, esn" />
    </Helmet>
    <header className="site__header">
      <a href="/">
        <Logo className="site__logo" alt="osRoots" />
      </a>
      <ThemeToggler>
        {({ theme, toggleTheme }) => (
          <label className="toggle-mode">
            <input
              className="toggle-mode__input"
              type="checkbox"
              onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
              checked={theme === 'dark'}
            />{' '}
            <Mode className="toggle-mode__icon" />
          </label>
        )}
      </ThemeToggler>
    </header>
    <main>{children}</main>
    <footer></footer>
  </div>
);

export default TemplateWrapper;
